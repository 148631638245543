<template>
  <CommonPage id="about" title="About Us">
    <section class="terms-content">
      <div class="c-title_wrap cc-legal_content">
        <h3 class="heading-small">
          About Us
        </h3>
      </div>

      <div class="c-text_wrap cc-legal">
        <p class="c-text_legal">
          Welcome to {{ domainConfig?.title =='NEWS'?formattedHostName:domainConfig.title }}, your
          trusted source for comprehensive and timely news coverage.
          Founded with a mission to deliver accurate, balanced, and engaging journalism, we are
          committed to keeping you informed about the stories that matter most.
        </p>
      </div>

      <div class="c-title_wrap cc-legal_content">
        <h3 class="heading-small">
          Our Mission
        </h3>
      </div>

      <div class="c-text_wrap cc-legal">
        <p class="c-text_legal">
          At {{ domainConfig?.title =='NEWS'?formattedHostName:domainConfig.title }}, our mission is
          to provide reliable news and insightful analysis to our
          readers. We strive to uphold the highest standards of integrity and fairness in our
          reporting. Our team works tirelessly to bring you breaking news, in-depth features, and
          thought-provoking opinions from around the world.
        </p>
      </div>

      <div class="c-title_wrap cc-legal_content">
        <h3 class="heading-small">
          Our Values
        </h3>
      </div>

      <div class="c-text_wrap cc-legal">
        <div class="c-legal_list-wrap">
          <ul role="list" class="c-legal_list">
            <li>
              <strong>Accuracy:</strong> We prioritize factual reporting and double-check our
              sources to ensure the reliability of our content.
            </li>
            <li>
              <strong>Integrity:</strong> Our commitment to honest journalism drives us to report
              the news without bias or distortion.
            </li>
            <li>
              <strong>Independence:</strong> We maintain editorial independence to provide
              objective coverage free from external influences.
            </li>
            <li>
              <strong>Innovation:</strong> Embracing new technologies and platforms, we deliver
              news in ways that engage and inform our audience.
            </li>
          </ul>
        </div>
      </div>

      <div class="c-title_wrap cc-legal_content">
        <h3 class="heading-small">
          Contact Us
        </h3>
      </div>

      <div class="c-text_wrap cc-legal">
        <p class="c-text_legal">
          We value your feedback and encourage you to reach out with any questions, comments, or
          story tips. Connect with us through {{ formattedHost }}_support@gmail.com.
        </p>
      </div>
    </section>
  </CommonPage>
</template>

<script>
import '@/css/news/privacytermspc.scss';
import CommonPage from '@/pages/CommonPagePC.vue';
import { mapGetters } from 'vuex'

export default {
  components: {
    CommonPage
  },
  computed: {
    ...mapGetters({
      host: 'host',
      domainConfig: 'domainConfig',
    }),
    formattedHostName () {
      if (!this.host) return 'News';
      const parts = this.host.split('.');
      if (parts.length > 0) {
        const firstPart = parts[0];
        return firstPart.charAt(0).toUpperCase() + firstPart.slice(1) + ' Media';
      }
      return '';
    },
    formattedHost () {
      if (!this.host) return 'news_media';
      const parts = this.host.split('.');
      if (parts.length > 0) {
        const firstPart = parts[0];
        return firstPart;
      }
      return 'news_media';
    }
  },
};
</script>
